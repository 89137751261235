<template>
  <div>
    <!-- <v-app-bar app dense height="46px" color="white" light>
      <v-toolbar-title class="mx-auto d-flex text-center color-purple">
        <img src="/img/logo.png" height="19px" />
      </v-toolbar-title>
    </v-app-bar> -->

    <div class="container">
      <!-- TODO: dw, you (the search field) will be back! IJN, Amen :) -->

      <v-btn
        depressed
        rounded
        block
        to="/new/circle"
        color="pink lighten-1"
        class="py-2 px-3 body-2 special-alert justify-space-between text-capitalize"
      >
        <span class="black--text">
          <v-chip small class="white pink--text text--lighten-2 mr-4">
            New</v-chip
          >
          <span class="font-sen font-weight-bold white--text">
            Create your own Circle!
          </span>
        </span>
        <v-icon small right color="yellow darken-1">
          {{ mdiStar }}
        </v-icon>
      </v-btn>

      <p class="caption text--secondary text-center mt-1">
        A Circle is a community of students
      </p>

      <p v-if="!fetchError" class="caption text--secondary mt-1 mb-1">
        All Circles
      </p>

      <v-list v-if="fetched" two-line class="transparent">
        <template v-for="(circle, index) in circles">
          <v-list-item :key="circle.slug" @click="view(index)">
            <template v-slot:default>
              <v-list-item-avatar>
                <v-avatar v-if="circle.display_picture" size="40">
                  <v-img width="40" height="40" :src="circle.display_picture">
                  </v-img>
                </v-avatar>

                <v-avatar v-else :color="color(index)" size="40">
                  <span class="white--text">
                    {{ circle.name.charAt(0).toUpperCase() }}
                  </span>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-medium"
                  v-text="circle.name"
                ></v-list-item-title>
                <v-list-item-subtitle class="p paragraph text-light">
                  Click to preview...
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  v-if="!$store.getters.belongsToCircle(circle.slug)"
                  small
                  outlined
                  :loading="buttons[`${circle.id}-button-loading`]"
                  @click.stop="join(circle)"
                  class="bg-purple white--text"
                >
                  <v-icon small class="white--text">
                    {{ mdiPlus }}
                  </v-icon>
                  Join
                </v-btn>
                <v-btn
                  v-else
                  small
                  color="purple-outline color-purple lighten-3"
                >
                  <v-icon small color="white"> </v-icon>
                  Joined
                </v-btn>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider v-if="index + 1 < circles.length" :key="index"></v-divider>
        </template>
      </v-list>

      <v-row v-else>
        <v-col cols="12">
          Getting Circles
        </v-col>
        <v-col cols="12">
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>

      <div
        v-if="fetchError"
        class="d-flex text-center justify-center align-center"
        style="height: 420px"
      >
        <div class="text-center">
          <!-- <img class="py-3 empty-folder" src="/img/icons/empty-folder.png" /> -->
          <span
            class="subtitle-2 d-block text-light lighten mt-5 mb-2"
            style="width: 200px"
            >Couldn't get Circles :/</span
          >
          <p class="caption secondary--text">
            Please try again by reloading
          </p>
        </div>
      </div>

      <!-- Bottom Sheet -->
      <v-bottom-sheet
        v-model="sheet"
        v-touch="{
          up: () => goTo(selectedCircle),
          down: () => {
            sheet = !sheet;
          }
        }"
        inset
      >
        <v-card
          v-touch="{
            up: () => goTo(`/c/${selectedCircle.slug}`),
            down: () => {
              sheet = !sheet;
            }
          }"
          @click="goTo(`/c/${selectedCircle.slug}`)"
        >
          <v-img
            class="white--text align-end"
            height="200px"
            :src="
              selectedCircle.banner ||
                'https://i.ibb.co/s9YGcYP/edited-sharon-mccutcheon-TZZw-C-xs-Cl-Y-unsplash.jpg'
            "
            gradient="to bottom, rgba(100, 115, 201, 0), rgba(0,0,0,0.83)"
          >
            <v-card-title>
              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar
                    v-if="selectedCircle.display_picture"
                    color="white"
                    size="45"
                  >
                    <v-img
                      width="45"
                      height="45"
                      :src="selectedCircle.display_picture"
                    >
                    </v-img>
                  </v-avatar>

                  <!-- TODO: MOve to separate component -->
                  <v-avatar
                    v-else
                    class="white--text text-capitalize "
                    size="45px"
                    color="green"
                  >
                    <span class="white--text">
                      {{
                        selectedCircle.name
                          ? selectedCircle.name.charAt(0).toUpperCase()
                          : 'NAN'
                      }}
                    </span>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="headline white--text">{{
                    selectedCircle.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="caption white--text"
                    >~{{ selectedCircle.slug }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="white--text"
                    >{{ selectedCircle.users }}
                    {{ selectedCircle.users == 1 ? 'Member' : 'Members' }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action style="position: absolute; right: 2px">
                  <v-btn
                    v-if="!$store.getters.belongsToCircle(selectedCircle.slug)"
                    :loading="buttons[`${selectedCircle.id}-button-loading`]"
                    small
                    text
                    class="primary white--text elevation-1"
                    @click.stop="join(selectedCircle)"
                  >
                    <v-icon small color="white">
                      {{ mdiPlus }}
                    </v-icon>
                    Join
                  </v-btn>
                  <v-btn
                    v-else
                    @click.stop="leave(selectedCircle)"
                    small
                    depressed
                    :loading="buttons[`${selectedCircle.id}-button-loading`]"
                    class="error white--text elevation-1"
                  >
                    <v-icon small color="white"> {{ mdiMinus }} </v-icon>
                    Leave
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-card-title>
          </v-img>

          <v-card-subtitle class="mt-2 pb-0">About</v-card-subtitle>

          <v-card-text class="text--primary">
            <div>{{ selectedCircle.description }}</div>
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
      <!-- Bottom Sheet -->
    </div>
  </div>
</template>
<script>
import http from '@/plugins/http';
import { mdiMinus, mdiPlus, mdiStar } from '@mdi/js';
export default {
  name: 'Circles',
  data: () => ({
    circles: [],
    fetched: false,
    joinLoading: false,
    selectedCircle: {},
    sheet: false,
    fetchError: false,
    buttons: {},
    // icons
    mdiMinus,
    mdiPlus,
    mdiStar
  }),
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    isMaestro() {
      if (!this.$store.getters.user) {
        return false;
      }
      let user = JSON.parse(window.localStorage.getItem('bubbl_user'));

      return user.badges.some(badge => {
        return 'maestro' == badge.badge.name.trim().toLowerCase();
      });
    }
  },
  methods: {
    goTo(url) {
      this.$router.push(url);
    },
    // TODO: look at functional components in Vue
    join(circle) {
      if (!this.loggedIn) {
        this.$store.dispatch('SHOW_AUTH_PROMPT', {
          message: 'Yo!'
        });

        return;
      }

      this.buttons[`${circle.id}-button-loading`] = true;

      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };
      this.joinLoading = true;
      http
        .post(
          `/v1/circle/${circle.slug}/users/`,
          { data: 'yarns' },
          { headers }
        )
        .then(response => {
          console.log('res => ', response.data);
          this.$store.dispatch('JOIN_CIRCLE', circle);

          this.sheet = false;
          if (this.selectedCircle) this.selectedCircle.users++;
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Joined circle successfully',
            style: 'info darken-1',
            withAction: true,
            actionText: 'View',
            actionLink: {
              name: 'View Circle',
              params: { circle_slug: circle.slug }
            }
          });
        })
        .catch(() => {
          this.$store.dispatch('SHOW_TOAST', {
            message: "Couldn't join circle, please try again",
            style: 'error'
          });
        })
        .finally(() => {
          this.buttons[`${circle.id}-button-loading`] = false;

          this.joinLoading = false;
        });
    },
    leave(circle) {
      if (!this.loggedIn) {
        this.$store.dispatch('SHOW_AUTH_PROMPT', {
          message: 'Yo!'
        });

        return;
      }

      this.buttons[`${circle.id}-button-loading`] = true;

      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };
      this.joinLoading = true;
      http
        .delete(`/v1/circle/${circle.slug}/users/`, { headers })
        .then(response => {
          console.log('res => ', response.data);
          // this.$router.push({ name: 'Feed' });
          this.$store.dispatch('LEAVE_CIRCLE', circle.slug);
          this.selectedCircle.users--;
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Left circle successfully',
            style: 'info'
          });

          this.sheet = false;
        })
        .catch(response => {
          console.log('res => ', response.data);
          this.$store.dispatch('SHOW_TOAST', {
            message: "Couldn't leave circle, please try again",
            style: 'error'
          });
        })
        .finally(() => {
          this.buttons[`${circle.id}-button-loading`] = false;

          this.joinLoading = false;
        });
    },
    getCircles() {
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      let headers = {
        Authorization: `token ${token}`
      };

      if (!token) {
        headers = {};
      }

      http
        .get('/v1/circles/', { headers })
        .then(response => {
          this.circles = response.data.results;
          this.circles.forEach(c => {
            this.$set(this.buttons, `${c.id}-button-loading`, false);
            this.$set(this.buttons, `${c.id}-button-done`, false);
          });
        })
        .catch(response => {
          this.fetchError = true;
          this.$store.dispatch('SHOW_TOAST', {
            message: "Couldn't get circles, please try again :(",
            style: 'error'
          });
          console.log('Error Response => ', response);
        })
        .finally(() => {
          this.fetched = true;
        });
    },
    view(index) {
      this.sheet = !this.sheet;
      this.selectedCircle = this.circles[index];
    },
    color(index) {
      let colors = [
        'cyan lighten-2',
        'green lighten-2',
        'indigo lighten-2',
        'pink lighten-2'
      ];

      return colors[index];
    }
  },
  mounted() {
    this.getCircles();
  },
  head: {
    title: 'All Circles'
  }
};
</script>
<style scoped>
.v-main {
  padding-top: 46px !important;
}

.special-alert {
  border-radius: 25px !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: #795fdc !important;
}
</style>
